import React, { useEffect, useState } from "react";
import {Table} from "react-bootstrap";
import { ATTENDANCE_TYPE, JOIN_TIME, LEAVE_TIME, NAME, TIME_ATTENDED, DROPS } from "../../constants/report.constant";
import { attachHoverforEllipsedToolTip } from "../../helper/commonUtils";
import ToolTipModal from "./ToolTipModal";

const GroupClassTable = ({ participantList }) => {
  const [participantToolTipModal, setParticipantToolTipModal] = useState(false);
  const [dropRejoinTime, setDropRejoinTime] = useState([])
  
  const closeParticipantToolTipModal = () => setParticipantToolTipModal(false);
  useEffect(() => {
    attachHoverforEllipsedToolTip("vve-tool-tip-group-class-table")
  }, [participantList]);
  
  const participantDropCountClick = (participant) => {
      setParticipantToolTipModal(true);
      setDropRejoinTime(participant)
  }

  return (  
    <Table hover bordered className="vve-group-class-table">
      <thead>
        <tr className="vve-text-bold">
          <th>{NAME}</th>
          <th className="vve-text-center">{DROPS}</th>
          <th className="vve-text-center">{ATTENDANCE_TYPE}</th>
          <th className="vve-text-center">{JOIN_TIME}</th>
          <th className="vve-text-center">{LEAVE_TIME}</th>
          <th className="vve-text-center">{TIME_ATTENDED}</th>
        </tr>
      </thead>
      <tbody>
      {participantList?.map((participant) => (
         <tr key={participant.name}>
          <td className='vve-tool-tip-group-class-table'> 
           {participant.name}
          </td>
          <td className="vve-text-center vve-drop-column">
              {participant.drops>0 && (<p onClick={()=> participantDropCountClick(participant)}> {participant.drops}</p>) }
          </td>
          <td className="vve-text-center">{participant.role}</td>
          <td className="vve-text-center">{participant.joinTime}</td>
          <td className="vve-text-center">{participant.leaveTime}</td>
          <td className="vve-text-center">{participant.timeAttended}</td>
        </tr>
      ))}
      </tbody>
      <ToolTipModal isOpen={participantToolTipModal} onClose={closeParticipantToolTipModal} participantData={dropRejoinTime}></ToolTipModal>
    </Table>
  );
};

export default GroupClassTable;